// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-website-tsx": () => import("./../../../src/pages/about-website.tsx" /* webpackChunkName: "component---src-pages-about-website-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-templates-oss-license-tsx": () => import("./../../../src/templates/oss-license.tsx" /* webpackChunkName: "component---src-templates-oss-license-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-standard-tsx": () => import("./../../../src/templates/standard.tsx" /* webpackChunkName: "component---src-templates-standard-tsx" */)
}

